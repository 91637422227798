<mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" class="d-block d-md-none sidenav-container">
        <div class="logo-img">
            <img src="assets/axis-logo.png" alt="" class="img-fluid">
            <div class="icon-cancel" (click)="sidenav.toggle()"></div>
        </div>
        <div class="sidenav-list">
            <div>
                <div class="list-item" [ngClass]="{'active': showBenefits}">Benefits
                    <span class="icon-down-open-mini up-down" (click)="showBenefits = !showBenefits"
                        *ngIf="!showBenefits"></span>
                    <span class="icon-up-open-mini up-down" (click)="showBenefits = !showBenefits"
                        *ngIf="showBenefits"></span>
                </div>
                <div class="bft-container" *ngIf="showBenefits">

                    <div class="each-bft" (click)="navigateTo(each.url)" *ngFor="let each of benefits">
                        <img [src]="each.img" alt="" class="img-fluid bft-img">
                        <div class="w100 pad-top-4">
                            <div class="d-flex justify-content-between bft-name">
                                <span [ngStyle]="{'color': each.color}">{{each.name}}</span>
                                <div class="icon-down-open-mini up-down-bft"
                                    (click)="$event.stopPropagation();each.show = !each.show" *ngIf="!each.show"></div>
                                <span class="icon-up-open-mini up-down-bft"
                                    (click)="$event.stopPropagation();each.show = !each.show" *ngIf="each.show"></span>

                            </div>
                            <div class="mar-top-10 bft-desc" *ngIf="each.show">{{each.desc}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="list-item mar-top-20" (click)="navigateTo('/offers')">Offers
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header [sidenav]="sidenav"></app-header>
        <div class="app">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>