import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private login = new BehaviorSubject(true);
  login$ = this.login.asObservable();

  apiUrlBsV1 = environment.apiUrlBsV1;

  constructor(
    private api: ApiService
  ) { }
  
  getAllPromoCode(service_name?: string) {
    return this.api
      .get(this.apiUrlBsV1 + 'promo_codes');
  }

  getPromoCodeDiscount(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode
    }
    return this.api
      .post(this.apiUrlBsV1 + 'promo_codes/apply', body);
  }

  removeCouponCode(bookingId, promoCode) {
    let body = {
      booking_id: bookingId,
      code: promoCode
    }
    return this.api
      .post(this.apiUrlBsV1 + 'promo_codes/remove', body);
  }

  emitLogin(data: any) {
    this.login.next(data)
  }

  public getRemainingTimeInHrs(timeString: string) : string {
    // Calculate remaining time in hours
    const remainingTime: number = timeString ? Math.ceil((new Date(timeString).getTime() - new Date().getTime())/3600000): 1;// taking default as 1 hour
    return `${remainingTime} hour${remainingTime > 1 ? 's' : '' }`
  }
}
