import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { IndianCurrency } from './pipes/indian-currency.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { NgxPaginationModule } from "ngx-pagination";
import { NgbDatepickerModule, NgbModule, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CreditCardDirective } from './directives/credit-card.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NoDataComponent } from './components/no-data/no-data.component';
import { CleanHtmlPipe } from './pipes/clean-html.pipe';
import { MaterialModule } from './modules/material/material.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxDaterangepickerMd } from './components/daterangepicker';
import { PaymentComponent } from './components/payment/payment.component';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { CouponCodeComponent } from './components/coupon-code/coupon-code.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { OfferCardComponent } from '../offers/offer-card/offer-card.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
    declarations: [
        // Components
        LoaderComponent,
        NoDataComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        PaymentComponent,
        CouponCodeComponent,
        StatusLabelComponent,
        OfferCardComponent,
        // Directives
        CreditCardDirective,
        ImagePreloadDirective,
        NumbersOnlyDirective,
        // Pipes
        IndianCurrency,
        FilterPipe,
        ReplacePipe,
        MinuteSecondsPipe,
        CleanHtmlPipe,
        CreditCardMaskPipe,
        RemoveUnderscorePipe,
        SafeUrlPipe,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: true,
            // disableTimeOut: true
        }),
        NgxPaginationModule,
        NgbDatepickerModule,
        NgbModule,
        BsDropdownModule.forRoot(),
        NgOtpInputModule,
        NgxDaterangepickerMd.forRoot({
            firstDay: 0,
        }),
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        CarouselModule,
        CollapseModule.forRoot()
    ],
    exports: [
        // Modules
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgbModule,
        BsDropdownModule,
        NgOtpInputModule,
        NgxDaterangepickerMd,
        ModalModule,
        NgbDatepickerModule,
        PopoverModule,
        CarouselModule,
        CollapseModule,
        // Components
        LoaderComponent,
        NoDataComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        PaymentComponent,
        CouponCodeComponent,
        StatusLabelComponent,
        OfferCardComponent,
        // Directives
        CreditCardDirective,
        ImagePreloadDirective,
        NumbersOnlyDirective,
        // Pipes
        IndianCurrency,
        FilterPipe,
        ReplacePipe,
        MinuteSecondsPipe,
        CleanHtmlPipe,
        CreditCardMaskPipe,
        RemoveUnderscorePipe,
        SafeUrlPipe,
    ],
    providers: []
})
export class SharedModule { }
