import { Component, OnInit, Input, TemplateRef, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OfferService } from '../services/offer/offer.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoginComponent } from 'src/app/shared/components/login/login.component';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfferCardComponent implements OnInit {
  @Input() merchantDetails;
  modalRef: BsModalRef | null;
  showLoader: boolean = false;
  selectedOffer;
  voucherData;
  showCouponPop;
  couponLoading;
  @Output() claimOfferEvent = new EventEmitter();
  @ViewChild('template') template;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog,
    private offerService: OfferService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'offer-claim-modal' });
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  navigateToDetailPage() {
    // const path = this.merchantDetails.name.split(' ').join('-').split('/').join('-') + '-' + this.merchantDetails.id;
    this.router.navigate(['/offers/detail/' + this.merchantDetails.id]);
  }

  preventClick(event) {
    event.stopPropagation();
  }

  redeemOffer(i) {
    this.closeModal();
    this.claimOfferEvent.emit({ merchantId: this.merchantDetails.id, offerIndex: i });
  }

  claimOffer() {
    if (this.merchantDetails.attributes.offers.data.length > 1) {
      this.openModal(this.template);
    } else {
      this.redeemOffer(0);
    }
  }

  allOfferClaimed(offers) {
    if (this.authService.isAuthenticated()) {
      for (const each of offers) {
        if (each.attributes.claimable) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
}
