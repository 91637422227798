import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

declare var Razorpay: any;

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [],
  providers: [
  ]
})
export class PaymentModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
        ngModule: PaymentModule,
        providers: [
            {
                provide: 'env', // you can also use InjectionToken
                useValue: environment
            },
            {
              provide: 'Razorpay',
              useValue: Razorpay
            }
        ]
    };
  }
}
