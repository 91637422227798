import { CommonService } from 'src/app/shared/services/common/common.service';
import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { Subscription } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { appConstants } from '../../constants/app.constants';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  resendActive = false;
  loginUser;
  interval;
  type = 'login';
  loginSubmit = false;
  otpSubmit = false;
  signupSubmit = false;
  firstTimeUserData;
  otpTimer = 180;
  progress = false;
  signInError;
  otpstr: any;
  errorMsg: string = ''
  otpErrorMsg: string = '';
  otp1: string = '';
  tnc_check: boolean = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobilePattern = /^\d{10}$/;
  // otp: string
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      'border': 'none',
      'border-bottom': '1px solid black',
      'border-radius': '0px',
      'font-size': "1em",
      'width': '30px',
    }
  };
  checkUserLoader: boolean = false;
  checkUserSubscription: Subscription;
  generateOtpSubscription: Subscription;
  validateOtpSubscription: Subscription;

  userAlreadyExist: boolean = false;
  mobile = new UntypedFormControl('', [Validators.required, Validators.pattern(this.mobilePattern)]);
  email = new UntypedFormControl('', [Validators.pattern(this.emailPattern)]);
  userId;
  userStatus = 'login';
  appConstants = appConstants;

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    public toastr: ToastrService,
    public router: Router,
    private commonService: CommonService
    // public dataS: DataSharingService
    // public header: HeaderComponent
  ) {
    dialogRef.backdropClick().subscribe(() => {
      this.onNoClick();
    });
  }

  checkUser(event) {
    if (this.checkUserSubscription) {
      this.checkUserSubscription.unsubscribe();
    }
    if (this.generateOtpSubscription) {
      this.generateOtpSubscription.unsubscribe();
    }
    this.loginSubmit = false;
    this.type = 'login';
    this.checkUserLoader = false;
    this.userAlreadyExist = false;
    if (this.mobile.value && this.mobile.value.length == 10) {
      this.checkUserLoader = true;
      this.auth.validateExistingUser(this.mobile.value).subscribe((res: any) => {
        if (res) {
          this.userId = res.id;
          this.userAlreadyExist = true;
          // this.onContinue();
        }
        this.checkUserLoader = false;
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close("redirect");
  }

  ngOnInit() {
    // this.startTimer()
    if (window.innerWidth < 576) {
      this.config.inputStyles["font-size"] = '10px';
    }
  }

  otp() {
    // this.setTimer();

    this.otp1 = '';
    this.otpErrorMsg = ''
    this.type = "otp";
  }

  startTimer() {
    this.otpTimer = 180;
    this.interval = setInterval(() => {
      if (this.otpTimer > 0) {
        this.otpTimer--;
      } else {
        // this.otpTimer = 180;
        // this.resendActive = true;
        clearInterval(this.interval)
      }
    }, 1000)

  }

  login() {
    this.type = "login";
    this.clearTimer();
  }

  clearTimer() {
    clearInterval(this.interval)
    this.otpTimer = 180;
  }

  changeNonStop(event) {
    this.tnc_check = event.target.checked;
    this.errorMsg = '';
    console.log(this.tnc_check);
  }

  signupUser(phone, email, tnc_check) {
    this.signupSubmit = true;
    if (phone.invalid) return;
    if (email.invalid) return;
    if (tnc_check) {
      this.errorMsg = '';
      this.signupSubmit = true;
      this.progress = true;
      this.auth.createUser(phone.value, email.value).then((res: any) => {
        this.progress = false;
        this.userId = res.id;
        this.loginUser = res.mobile;
        this.loginSubmit = false;
        this.resendActive = true;
        this.otp();
        this.startTimer()
      });
    } else {
      this.errorMsg = 'Please accept terms and conditions.';
    }
  }

  requestUserOtp() {
    this.loginSubmit = true;
    this.generateOtpSubscription = this.auth.requestUserOtp(this.mobile.value, this.userId).subscribe((res: any) => {
      this.loginUser = this.mobile.value;
      this.loginSubmit = false;
      this.resendActive = true;
      this.startTimer()
      if (res) {
        this.errorMsg = '';
        this.otp();
      } else {
        this.errorMsg = 'OTP Request limit exceeded. Please try after 30 mins.';
      }
    }, err => {
      const time = this.commonService.getRemainingTimeInHrs(err?.error?.user_unlock_at);
      this.loginSubmit = false;
      this.errorMsg = `OTP Request limit exceeded. Please try after ${time}.`;
    });
  }

  resendOTP() {
    this.resendActive = false;
    this.requestUserOtp();
  }

  signInUser(otp) {
    if (otp.length === 0) {
      this.otpErrorMsg = 'OTP is required';
      // this.otp1 = '';
    } else if (otp.length > 0 && otp.length < 6) {
      this.otpErrorMsg = 'OTP must contain 6 digits';
      // this.otp1 = '';
    } else {
      this.otpErrorMsg = '';
      this.progress = true;
      this.validateOtpSubscription = this.auth.validateUserOtp(this.userId, otp, this.userStatus).subscribe((
        response: any) => {
        if (response && response.token) {
          this.otpSubmit = false;
          this.errorMsg = '';
          this.toastr.success('Logged in successfully.');
          localStorage.setItem('token', response.token);
          this.auth.getUserDetails().subscribe((result: any) => { });
          this.dialogRef.close("true");
        } else {
          this.otpSubmit = false;
          this.otpErrorMsg = 'Please check the entered OTP.';
          this.progress = false;
          if (!response.wrong_attempts_left) {
            let time;
            if (this.auth.clientDetails && this.auth.clientDetails.attributes && this.auth.clientDetails.attributes.configs.otp_wrong_attempts_lock_duration) {
              time = this.auth.clientDetails.attributes.configs.otp_wrong_attempts_lock_duration;
            }
            if (time) {
              this.errorMsg = `Sorry, You have reached the max OTP attempts. Please retry after ${Math.ceil(
                time / 60
              )} hour(s)`; // requirement is to show value ceiling value in hours.
              this.type = 'login';
              this.mobile.setValue('');
            }
          }
        }
      }, (error) => {
        this.otpSubmit = false;
        this.otpErrorMsg = 'Please check the entered OTP.';
        this.progress = false;
      });
      // this.otp1 = '';
    }
  }

  removeLetter(str) {
    this.errorMsg = '';
    str.target.value = (str.target.value.replace(/\D/g, ""));
  }

  removeLetterOTP(str) {
    if (str != this.otp1) {
      this.otp1 = str;
      this.otpErrorMsg = '';
      this.errorMsg = '';
      if(this.validateOtpSubscription) {
        this.validateOtpSubscription.unsubscribe();
      }
      this.validateOtp();
    }
  }

  isContinueDisabled() {
    if (this.checkUserLoader || this.mobile.invalid) {
      return true;
    }
    if (!this.userAlreadyExist && (this.email.invalid || !this.tnc_check)) {
      return true;
    }
    return false;
  }

  onContinue() {
    if (this.userAlreadyExist) {
      this.requestUserOtp();
    } else {
      this.signupUser(this.mobile, this.email, this.tnc_check);
    }
  }

  validateOtp() {
    if (this.otp1 && this.otp1.length == 6) {
      this.signInUser(this.otp1);
    }
  }

  resetLoginAgain() {
    this.type = 'login';
    this.mobile.setValue('');
    this.otpErrorMsg = '';
    this.errorMsg = '';
  }
}
