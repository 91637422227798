<div class="container home-container">


    <div>
        <!-- <owl-carousel-o [options]="carouselOption2">
            <ng-template carouselSlide> -->
        <div class="top-banner-container">
            <div class="banner-content">
                <div class="content-d">
                    <div class="heading">Your Health is your
                        <span class="f600">Greatest Wealth</span>
                    </div>
                    <div class="desc">
                        Build a healthier tomorrow with Axis Bank AURA Credit Card and enjoy exclusive benefits
                        and rewards.
                    </div>
                    <div class="d-flex align-center" *ngIf="!auth.isAuthenticated()">
                        <button mat-button class="primary-btn apply-now-btn" (click)="applyNow()">Apply Now</button>
                        <div class="explore-bft pointer linkEffect" (click)="goToBenefits()">Explore
                            Benefits
                            <span class="icon-right"></span>
                        </div>
                    </div>
                    <div class="d-flex align-center" *ngIf="auth.isAuthenticated()">
                        <button mat-button class="primary-btn explore-bft-btn" (click)="goToBenefits()">Explore
                            Benefits</button>
                    </div>

                </div>
                <div class="d-block d-md-none">
                    <div class="d-flex">
                        <img src="assets/homepage/top-banner2-mob.png" alt="" class="img-fluid m-auto">
                    </div>
                </div>
            </div>
        </div>
        <!-- </ng-template>
            <ng-template carouselSlide *ngIf="auth.isAuthenticated()">
                <div class="top-banner-container top-banner-container2">
                    <div class="banner-content">
                        <div class="content-d">
                            <div class="heading">Heading for the
                                <span class="f600">Card</span>
                            </div>
                            <div class="desc">
                                Build a healthier tomorrow with Axis Bank AURA Credit Card and enjoy exclusive benefits
                                and rewards.
                            </div>
                            <div class="d-flex categories-icons">
                                <div *ngFor="let each of apps; let i = index;" (click)="navigateTo(each.url)" class="category-icon pointer">
                                    <img [src]="each.img" alt="">
                                </div>
                            </div>
                            <div class="d-flex align-center">
                                <div class="d-flex align-center explore-bft pointer" (click)="goToBenefits()">Explore
                                    Benefits
                                    <span class="icon-right"></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-md-none">
                            <div class="d-flex absolute w-100 top-banner-mob-img">
                                <img src="assets/homepage/top-banner2-mob.png" alt="" class="img-fluid m-auto">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o> -->
    </div>
    <div class="benefit-container relative" #benefitContainer>
        <div class="heading">
            Virtual and In-person <span class="f600">Benefits</span>
        </div>

        <div class="benefits relative">
            <div class="d-flex bft2" #carousel>
                <div class="bft-1">
                    <div class="each-benefit " (click)="navigateTo('/doctor-consultation')">
                        <div class="bft-img">
                            <img src="assets/homepage/doc-con.png" alt="" class="img-fluid">
                        </div>
                        <div class="bft-detail">
                            <div class="name dc">Online Doctor Consultations</div>
                            <div class="desc">Get 4 complimentary consultations per month for your family (48 in a
                                year).
                            </div>
                            <div class="know-more linkEffect">
                                Know More
                                <span class="icon-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bft-1">
                    <div class="each-benefit " (click)="navigateTo('/health-checkup')">
                        <div class="bft-img">
                            <img src="assets/homepage/health-ch.png" alt="" class="img-fluid">
                        </div>
                        <div class="bft-detail">
                            <div class="name ht">Health Tests & Checkups</div>
                            <div class="desc">Get up to 50% discount on packages, plus, a complimentary Rs.500 voucher.
                            </div>
                            <div class="know-more linkEffect">
                                Know More
                                <span class="icon-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bft-1">
                    <div class="each-benefit " (click)="navigateTo('/fitness')">
                        <div class="bft-img">
                            <img src="assets/homepage/fitness-cl.png" alt="" class="img-fluid">
                        </div>
                        <div class="bft-detail">
                            <div class="name fc">Personal Training & Fitness</div>
                            <div class="desc">Get 4 free Personal Training sessions per month (48 in a year), and 16
                                pre-recorded videos per month.
                            </div>
                            <div class="know-more linkEffect">
                                Know More
                                <span class="icon-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bft-1">
                    <div class="each-benefit " (click)="navigateTo('/nutrition')">
                        <div class="bft-img">
                            <img src="assets/homepage/nutri.png" alt="" class="img-fluid">
                        </div>
                        <div class="bft-detail">
                            <div class="name nu">Nutrition & Diet Plans</div>
                            <div class="desc">Get up to 50% discount from top nutrition subscriptions.
                            </div>
                            <div class="know-more linkEffect">
                                Know More
                                <span class="icon-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/homepage/arrow-left.png" alt="" class="img-fluid arrow-left d-none d-md-block pointer"
                (click)="previous()">
            <img src="assets/homepage/arrow-right.png" alt="" class="img-fluid arrow-right d-none d-md-block pointer"
                (click)="next()">
        </div>

    </div>

    <div class="welcome-reward-container relative pointer" (click)="navigateTo('/welcome-benefit')"
        *ngIf="!auth.isAuthenticated() || (auth.isAuthenticated() && wlcOfferDetail?.claimable && showDecathlonVoucher)">
        <img src="assets/homepage/decathlon.png" alt="" class="img-fluid d-none d-md-block w-100">
        <img src="assets/homepage/decathlon-mob.png" alt="" class="img-fluid d-block d-md-none w-100">

        <div class="w-detail">
            <div class="heading">A Complimentary Serving Of Sport</div>
            <!-- <div class="separator"></div> -->
            <div class="desc">Get a <span class="rupee-symbol">₹
                </span>750 Omni Gift Card from Decathlon when you make your first transcation with the Axis
                Bank AURA Credit Card!</div>
            <div class="unlock pointer">Unlock your Welcome Reward
                <span class="icon-right"></span>
            </div>
            <div class="tnc">*T&Cs apply</div>
        </div>
    </div>

    <div class="welcome-reward-container relative pointer p-2 cursor mt-4 mb-4" (click)="navigateTo('/welcome-benefit')"
        *ngIf="!auth.isAuthenticated() || (auth.isAuthenticated() && wlcOfferDetail?.claimable && showAmazonVoucher)">
        <img src="assets/homepage/AmazonVoucher.jpg" alt="" class="img-fluid d-none d-md-block w-100">
        <img src="assets/homepage/AmazonVoucher.jpg" alt="" class="img-fluid d-block d-md-none w-100">
    </div>

    <div class="popular-offer-container" *ngIf="merchantList?.length">
        <div class="title"><span class="f600">Popular</span> Offers</div>
        <!-- <div class="view-all pointer linkEffect" (click)="navigateTo('/offers')">View all</div> -->
        <div class="offers d-flex">
            <ng-container *ngFor="let merchant of merchantList; let i = index">
                <div class="col-4 each-offer mar-top-30">
                    <app-offer-card [merchantDetails]="merchant" (claimOfferEvent)="claimOffer($event)">
                    </app-offer-card>
                </div>
            </ng-container>
        </div>
        <div class="see-all-container text-center">
            <button mat-button class="primary-btn" (click)="navigateTo('/offers')">See all offers
                <span class="icon-right mar-left-5"></span>
            </button>
        </div>

    </div>

    <div class="apply-now-container" *ngIf="!auth.isAuthenticated()">
        <div class="banner-content">
            <div class="content-d">
                <div class="heading">Your Health is your
                    <span class="f600">Greatest Wealth</span>
                </div>
                <div class="desc">
                    Build a healthier tomorrow with Axis Bank AURA Credit Card and enjoy exclusive benefits and rewards.
                </div>
                <div class="d-flex align-center">
                    <button mat-button class="primary-btn apply-now-btn" (click)="applyNow()">Apply Now</button>
                </div>
            </div>
        </div>

    </div>



</div>

<div class="modal couponmodal fade" bsModal #couponmodal="bs-modal" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name" [config]="{ show: true, keyboard : true,backdrop : 'static' }"
    *ngIf="showCouponPop">
    <div class="modal-outer" (click)="showCouponPop = false"></div>
    <div class="modal-dialog modal-lg" *ngIf="selectedOffer">
        <div class="modal-content modal-bg">
            <div *ngIf="couponLoading" class="flex align-center justify-center h100 loader-coupon">
                <div class="page-loader"></div>
            </div>
            <div *ngIf="!couponLoading">
                <div class="modal-body coupon-code-pop text-center" *ngIf="voucherData && voucherData.success">
                    <div class="merchant-logo-contain"><img src="assets/axis-logo.png"></div>
                    <div class="odder-name flex justify-center align-center">
                        <div class="modal-mer-logo">
                            <img default="'https://images.poshvine.com/b/default-thumbnail-square.jpg'"
                                [src]="selectedOffer?.merchant_logo" class="img-fluid">
                        </div>
                        <span class="offer-text flex align-center ellipse f500">{{selectedOffer?.merchant_name}}</span>
                    </div>
                    <div class="coupon-offer-name pad-top-5 pad-bot-12 fnt-13">{{selectedOffer.offer_title}}</div>
                    <div class="divider-top">
                        <img src="./assets/divider.png">
                    </div>
                    <div class="coupon-code-main text-center flex-wrap">

                        <div class="coupan-code-box relative" id="redemption_coupon_code"
                            *ngIf="selectedOffer?.offer_type != 'no_voucher'">
                            <span class="fnt-17 mar-right-10"><b>{{voucherData.voucher_code}}</b></span>
                            <span class="icon-copy pointer" (click)="copy(voucherData.voucher_code)"></span>
                        </div>
                        <div class="coupan-code-box relative" id="redemption_coupon_code"
                            *ngIf="selectedOffer?.offer_type == 'no_voucher'">
                            <span class="fnt-17">
                                <b>No Voucher Required</b>
                            </span>
                        </div>
                        <div class="validity-box" *ngIf="selectedOffer?.end_date">
                            <small id="validity_coupon_code_return">Valid till:
                                <span>{{selectedOffer?.end_date | date: 'mediumDate'}}</span></small>
                        </div>
                    </div>
                    <div class="divider-bottom">
                        <img src="./assets/divider.png">
                    </div>
                    <div class="coupon-code-footer ">
                        <div class="fnt-12 f300">Please refer to the Offer Terms and Conditions on how to redeem this
                            offer</div>
                        <div class="text-center go-to mar-top-15 mar-bot-5">
                            <!-- <img class="loader" width="31px" *ngIf="redeeming" src = "./assets/img/icons/spin.gif"> -->
                            <button mat-button class="primary-btn redeem-btn-2" (click)="goTo()">Redeem</button>
                        </div>
                    </div>
                </div>
                <div class="modal-body coupon-error text-center" *ngIf="voucherData && voucherData.success == false">
                    <svg class="mar-top-48" fill="red" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                        viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;"
                        xml:space="preserve">
                        <g>
                            <path
                                d="M503.839,395.379l-195.7-338.962C297.257,37.569,277.766,26.315,256,26.315c-21.765,0-41.257,11.254-52.139,30.102    L8.162,395.378c-10.883,18.85-10.883,41.356,0,60.205c10.883,18.849,30.373,30.102,52.139,30.102h391.398    c21.765,0,41.256-11.254,52.14-30.101C514.722,436.734,514.722,414.228,503.839,395.379z M477.861,440.586    c-5.461,9.458-15.241,15.104-26.162,15.104H60.301c-10.922,0-20.702-5.646-26.162-15.104c-5.46-9.458-5.46-20.75,0-30.208    L229.84,71.416c5.46-9.458,15.24-15.104,26.161-15.104c10.92,0,20.701,5.646,26.161,15.104l195.7,338.962    C483.321,419.836,483.321,431.128,477.861,440.586z" />
                        </g>
                        <g>
                            <rect x="241.001" y="176.01" width="29.996" height="149.982" />
                        </g>
                        <g>
                            <path
                                d="M256,355.99c-11.027,0-19.998,8.971-19.998,19.998s8.971,19.998,19.998,19.998c11.026,0,19.998-8.971,19.998-19.998    S267.027,355.99,256,355.99z" />
                        </g>
                    </svg>
                    <div class="fnt-16 ln-28 c333 error-line">Something went wrong. Please try again later!</div>
                </div>
            </div>
        </div>
    </div>
</div>