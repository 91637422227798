import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyDialogModule as MatDialogModule, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatNativeDateModule } from '@angular/material/core';

// import {
//   MatToolbarModule,
//   MatCardModule,
//   MatSelectModule,
//   MatButtonModule,
//   MatListModule,
//   MatExpansionModule,
//   MatInputModule,
//   MatCheckboxModule,
//   MatRadioModule,
//   MatTabsModule,
//   MatSidenavModule,
//   MatTableModule,
//   MatDialogModule, MAT_DIALOG_DATA,
//   MatProgressSpinnerModule,
//   MatMenuModule,
//   MatIconModule,
//   MatAutocompleteModule,
//   MatSliderModule,
//   MatProgressBarModule,
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatStepperModule,
// } from '@angular/material';
import { CdkStepperModule } from '@angular/cdk/stepper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatExpansionModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CdkStepperModule,
    MatStepperModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    MatDatepickerModule
  ],
  exports: [
    MatToolbarModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatExpansionModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSidenavModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CdkStepperModule,
    MatStepperModule,
  ]
})
export class MaterialModule { }
