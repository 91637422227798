<div class="offer-container pointer relative" (click)="navigateToDetailPage()">
  <div class="offer-img block">
    <img class="img-fluid zoom" loading="lazy" [src]="merchantDetails?.attributes?.offers.data[0]?.attributes?.image_url"
      default="https://images.poshvine.com/b/default-thumbnail-image.jpg">
  </div>
  <div class="merchant-logo absolute block">
    <img class="img-fluid" loading="lazy" [src]="merchantDetails?.attributes?.logo_url"
      default="https://images.poshvine.com/b/default-thumbnail-square.jpg">
  </div>
  <div class="offer-rtxt-data pad-top-35">
    <div class="offer-title text-truncate">
      {{merchantDetails?.attributes.name}}</div>
    <div class="desc-box block relative d-flex" [popover]="moreOfferTemplate" popoverTitle="Offers"
      triggers="mouseenter:mouseleave">
      <div class="offer-desc text-truncate">
        {{merchantDetails?.attributes?.offers.data[0]?.attributes?.title}}</div>
      <div *ngIf="merchantDetails?.attributes?.offers.data.length > 1" class="more-offer">
        {{merchantDetails.attributes.offers.data.length - 1}}
        more {{merchantDetails.attributes.offers.data.length - 1 > 1 ? 'offers' : 'offer'}}</div>
    </div>
    <ng-template #moreOfferTemplate>
      <div *ngFor="let offer of merchantDetails?.attributes?.offers.data;let j=index"
        class="d-flex align-center justify-content-between">
        <span>
          <span class="icon-check mar-right-5"></span>
          <span>{{offer.attributes.title}}</span>
        </span>
      </div>
    </ng-template>
    <div class="separator"></div>
    <div class="claim-block flex space-between align-center mar-top-20 mar-bot-12">
      <div>
        <div class="w100 fnt-16">
          <button mat-button class="w100 primary-btn claim-btn" (click)="preventClick($event);claimOffer()" mat-button
            [disabled]="merchantDetails?.attributes?.offers.data[0].showLoader"
            *ngIf="!allOfferClaimed(merchantDetails?.attributes?.offers.data) || !authService.isAuthenticated()">
            <div class="d-flex align-center justify-content-center">
              <div>Claim Offer</div>
              <div class="loader mar-left-10" *ngIf="merchantDetails?.attributes?.offers.data[0].showLoader">
              </div>
            </div>
          </button>
          <button mat-button class="w100 primary-btn claim-btn claimed-btn" (click)="preventClick($event);" mat-button
            *ngIf="allOfferClaimed(merchantDetails?.attributes?.offers.data) && authService.isAuthenticated()">
            <div class="d-flex align-center justify-content-center">
              <div>Claimed</div>
            </div>
          </button>
        </div>
      </div>

      <div class="ga-know-more know-btn block pointer f600 linkEffect">Know More</div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-container">
    <div class="close-btn flex justify-end">
      <span class="icon-cancel pointer" (click)="closeModal()"></span>
    </div>

    <div class="merchant-name-logo flex align-center mar-bot-20">
      <div class="mer-l"><img class="img-fluid" loading="lazy"
          default="https://images.poshvine.com/b/default-thumbnail-square.jpg"
          [src]="merchantDetails?.attributes?.logo_url">
      </div>
      <div class="mer-title f500 fnt-26">{{merchantDetails?.attributes.name}}</div>
    </div>


    <div class="single-offer-disp no-margin  bg-fff"
      *ngFor="let each of merchantDetails?.attributes?.offers.data; let i = index;">
      <div class="flex single-inside">
        <div class="col-9">
          <h3 class="offer-title fnt-16 mar-bot-15">{{each.attributes.title}}
          </h3>

          <div class="offer-desc" [innerHtml]="each.attributes.description"></div>

          <div class="flex align-center offer-below-lft" *ngIf="each.attributes.end_date">
            <span class="icon-group-1 mar-right-10"></span>
            <span class="mar-right-4">Valid till : </span><b>
              {{each.attributes.end_date | date: 'MMM dd, yyyy'}}</b>
          </div>

        </div>
        <div class="col-3">
          <div class="offer-coupon-code flex justify-end">
            <button mat-button class="primary-btn claim-btn" (click)="preventClick($event);redeemOffer(i)" mat-button
              [disabled]="merchantDetails?.attributes?.offers.data[i].showLoader"
              *ngIf="merchantDetails?.attributes?.offers.data[i].attributes.claimable || !authService.isAuthenticated()">
              <div class="d-flex align-center justify-content-center">
                <div>Claim Offer</div>
                <div class="loader mar-left-10" *ngIf="merchantDetails?.attributes?.offers.data[i].showLoader">
                </div>
              </div>
            </button>
            <button mat-button class="primary-btn claim-btn claimed-btn" (click)="preventClick($event);" mat-button
              *ngIf="!merchantDetails?.attributes?.offers.data[i].attributes.claimable && authService.isAuthenticated()">
              <div class="d-flex align-center justify-content-center">
                <div>Claimed</div>
              </div>
            </button> </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>