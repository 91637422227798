import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import { tap, map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrlCsV1;
  clientDetails;

  constructor(
    private api: ApiService
  ) { }

  getCurrentUser() {
    return this.api.get(this.apiUrl + 'user').toPromise();
  }

  validateExistingUser(mobile: string) {
    return this.api.get(this.apiUrl + 'users/search?mobile=' + mobile);
  }

  createUser(mobile: string, email: string) {
      return this.api.post(this.apiUrl + 'users', { mobile: mobile, email: email }).toPromise();
  }

  requestUserOtp(mobile: string, userId: string) {
    return this.api.post(this.apiUrl + 'users/' + userId + '/otps', { mobile: mobile, otp_type: "login" });
  }

  validateUserOtp(userId: string, otp: string, otpType: string) {
    return this.api.post(this.apiUrl + 'users/' + userId + '/otps/verify', { otp_type: otpType, code: otp });
  }

  logout() {
    localStorage.clear();
  }

  isAuthenticated() {
    return localStorage.getItem('token') ? true : false;
  }

  getUserDetails() {
    return this.api
      .get(this.apiUrl + 'user')
      .pipe(
        map(res => res || res),
				catchError(error => throwError(error)),
        tap((user) =>
          localStorage.setItem("login_details", JSON.stringify(user))
        )
      );
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('login_details'))) {
      const currentUserId = JSON.parse(localStorage.getItem('login_details')).id;
      return this.api.post(this.apiUrl + 'sessions/' + currentUserId, {}).toPromise();
    }
  }

  getClientDetails() {
    this.api.get(this.apiUrl + 'client').subscribe((res: any)=>{
      if(res && res.data) {
        this.clientDetails = res.data;
      }
    });
  }
  
}
