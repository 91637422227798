import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { appConstants } from './shared/constants/app.constants';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AuthService } from './shared/services/auth/auth.service';
import { RouterExtService } from './shared/services/router-ext/router-ext.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Axis Bank AURA Credit Card';
  showBenefits: boolean = true;

  benefits = appConstants.apps;
  @ViewChild('sidenav') sidenav;
  constructor(
    private router: Router,
    private update: SwUpdate,
    private authService: AuthService,
    private routerExtService: RouterExtService,
  ) {
    this.updateClient();
  }

  ngOnInit() {
    this.authService.getClientDetails();
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
    this.sidenav.toggle();
  }

  updateClient() {
    if (!this.update.isEnabled) {
      console.log('Not Enabled');
      return;
    }

    const updatesAvailable = this.update.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));

    this.update.activateUpdate().then((event: any) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }
}
