import { Component, OnInit } from '@angular/core';
import { appConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-booking-failed',
  templateUrl: './booking-failed.component.html',
  styleUrls: ['./booking-failed.component.scss']
})
export class BookingFailedComponent implements OnInit {
  appConstants = appConstants;
  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }

}
