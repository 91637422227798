import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { Error500Component } from './pages/error500/error500.component';
import { BookingFailedComponent } from './pages/booking-failed/booking-failed.component';
import { Error404Component } from './pages/error404/error404.component';

const routes: Routes = [
  { path: "", component: HomepageComponent },
  {
    path: "fitness",
    loadChildren: () =>
      import("./fitness/fitness.module").then(m => m.FitnessModule)
  },
  // {
  //   path: "doctor-consultation",
  //   loadChildren: () =>
  //     import("./docsapp/docsapp.module").then(m => m.DocsappModule)
  // },
  {
    path: "offers",
    loadChildren: () =>
      import("./offers/offers.module").then(m => m.OffersModule)
  },
  {
    path: "health-checkup",
    loadChildren: () =>
      import("./health-checkup/health-checkup.module").then(m => m.HealthCheckupModule)
  },
  {
    path: "doctor-consultation",
    loadChildren: () =>
      import("./practo/practo.module").then(m => m.PractoModule)
  },
  {
    path: "welcome-benefit",
    loadChildren: () =>
      import("./welcome-benefit/welcome-benefit.module").then(m => m.WelcomeBenefitModule)
  },
  {
    path: "booking",
    loadChildren: () =>
      import("./booking-history/booking-history.module").then(
        m => m.BookingHistoryModule
      )
  },
  {
    path: "offer-redemption",
    loadChildren: () =>
      import("./offer-redemption-history/offer-redemption-history.module").then(m => m.OfferRedemptionHistoryModule)
  },
  {
    path: "welcome-benefit-redemption",
    loadChildren: () =>
      import("./welcome-bft-redemption-history/welcome-bft-redemption-history.module").then(m => m.WelcomeBftRedemptionHistoryModule)
  },
  {
    path: "nutrition",
    loadChildren: () =>
      import("./nutrition/nutrition.module").then(m => m.NutritionModule)
  },
  { path: "error", component: Error500Component },
  { path: "booking-failed", component: BookingFailedComponent },
  {
    path: "info",
    loadChildren: () =>
      import("./info/info.module").then(m => m.InfoModule)
  },
  { path: "**", pathMatch: "full", component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
