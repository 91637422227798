<div class="container login-container">
  <div class="close-box pointer logo mar-bot-20">
    <img src="assets/axis-logo.png" alt="">
  </div>

  <section class="step-one" *ngIf="type === 'login'">
    <div class="title">Validate Your Mobile Number</div>
    <div class="row fields pad-top-20">
      <div class="col-sm-12">
        <div class="mar-bot-10 ft-sz-14 grey">Enter your mobile number registered with Axis Bank AURA Health
        </div>

        <div class="dialog-tel-field d-flex align-items-center">
          <span class="flag"></span>
          <svg class="input-line-field" width="1" height="48" viewBox="0 0 1 48" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="1" height="48" fill="#E3E5E7" />
          </svg>
          <input class="input-mobile-field" (input)="removeLetter($event);checkUser($event)" maxlength="10"
            minlength="10" type="tel" [formControl]="mobile">
          <div class="loader user-check-loader mar-right-20" *ngIf="checkUserLoader || loginSubmit"></div>
        </div>

        <div class="pad-top-15"></div>

        <!-- <ng-container *ngIf="!userAlreadyExist && mobile.valid && !checkUserLoader">
          <div class="mar-bot-10 ft-sz-14 grey">Enter your email address (optional)
          </div>

          <div class="email-wrap">
            <input class="" type="email" [formControl]="email">
          </div>

          <div class="d-flex align-items-center justify-content-start pad-top-16 pad-bot-5">
            <label class="filter-label save-later-checkn mb-0">
              <input type="checkbox" (change)="changeNonStop($event);" [checked]="tnc_check">
              <span class="checkmark save-later-checkmark"></span><span class="savetraveller-text fnt-14">
                <p class="no-margin">By signing in, you agree to our <a href="/info/terms-and-conditions"
                    target="_blank">Terms & Conditions</a> & <a href="/info/privacy-policy" target="_blank">Privacy
                    Policy</a>.</p>
              </span>
            </label>
          </div>
        </ng-container> -->
        <div class="error-txt fnt-12">{{errorMsg}}</div>
        <ng-container *ngIf="!userAlreadyExist && mobile.valid && !checkUserLoader">
          <div class="error-txt fnt-12">
            <div>Dear Customer, the entered mobile number is incorrect. Please check if you have entered correct mobile number</div><br>
            <div>If you are still facing issues,  please write to us at <a [href]="'mailto:'+ appConstants.basicInfo.email.val"
              class="contact-email">{{appConstants.basicInfo.email.display}}</a></div>
          </div>
        </ng-container>
        <button mat-button class="signup continue-button primary-btn" [disabled]="isContinueDisabled()" disabled
          (click)="onContinue()">Continue</button>

      </div>
    </div>
  </section>

  <section class="step-two" *ngIf="type === 'otp'">
    <div class="title">Enter the 6 digit OTP sent to +91 *******{{mobile.value | slice:7:10 }} <span
        class="back-btn pointer relative fnt-13" (click)="resetLoginAgain()">(Edit)</span></div>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div id="divOuter" class="flex align-center mar-top-20">
            <!-- <div class="enter-otp">Enter OTP</div> -->
            <div id="divInner">
              <ng-otp-input (onInputChange)="removeLetterOTP($event);" [config]="config"></ng-otp-input>
            </div>
          </div>

          <div class="error-txt fnt-12 mar-top-10">{{otpErrorMsg}}</div>
          <p *ngIf="resendActive && otpTimer" class="pad-top-16 pad-bot-20 m-0 field-message">Resend in
            {{otpTimer | minuteSeconds}} minutes</p>
          <button (click)="resendOTP()" class="resend-otp pointer fnt-12 f400 mar-top-4"
            *ngIf="resendActive && !otpTimer">Resend
            Code</button>

        </div>
      </div>
    </div>
  </section>

  <!-- <app-loader *ngIf="progress" style="margin:20px auto;" mode="indeterminate"></app-loader> -->

</div>