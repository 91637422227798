import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  apiUrl = environment.apiUrlPsV1;

  constructor(
    private api: ApiService,
    private authService: AuthService
  ) { }

  getCategories(params = {}) {
    let elgble = this.authService.isAuthenticated() ? true : false;
    params['parent_id'] = environment.healthWellnessId;
    return this.api
      .get(this.apiUrl + 'categories' + (elgble ? '?elgble=true' : ''), {
        params: params
      }).toPromise();
  }

  getMerchants(params) {
    // search by name
    let elgble = this.authService.isAuthenticated() ? true : false;
    return this.api
      .get(this.apiUrl + 'merchants' + (elgble ? '?elgble=true' : ''), { params: params });
  }

  getOffersByMerchant(merchantId) {
    let elgble = this.authService.isAuthenticated() ? true : false;
    let params = {};
    return this.api
      .get(this.apiUrl + 'offers?merchant_id=' + merchantId + (elgble ? '&elgble=true' : ''), { params: params })
      .toPromise();
  }

  getOffers() {
    let elgble = this.authService.isAuthenticated() ? true : false;
    let params = {};
    return this.api
      .get(this.apiUrl + 'offers' + (elgble ? '?elgble=true' : ''), { params: params })
      .toPromise();
  }

  getCategoryDetails(params = {}) {
    let elgble = this.authService.isAuthenticated() ? true : false;
    params['parent_id'] = environment.healthWellnessId;
    return this.api.get(this.apiUrl + 'category_details' + (elgble ? '?elgble=true' : ''), {
      params: params
    }).toPromise();
  }

  getCategoryDetail(id, params) {
    let elgble = this.authService.isAuthenticated() ? true : false;
    params['parent_id'] = environment.healthWellnessId;
    return this.api.get(this.apiUrl + 'category_details/' + id + (elgble ? '?elgble=true' : ''), {
      params: params
    }).toPromise();
  }

  redeem(offer, params) {
    return this.api.post(this.apiUrl + "offers/" + offer.id + "/redeem", params).toPromise();
  }

  getRedemptions(params = {}) {
    return this.api.get(this.apiUrl + "redemptions?", { params: params });
  }

}
