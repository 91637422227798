import { Component, OnInit, Input, OnChanges, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
// import { LoginComponent } from "../../../pages/signin/login/login.component";
// import { LoginService } from "src/app/services/login/login.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { appConstants } from "../../constants/app.constants";
import { LoginComponent } from "../login/login.component";
import { AuthService } from "../../services/auth/auth.service";
import { CommonService } from "../../services/common/common.service";
// import { DataSharingService } from "src/app/services/data-sharing.service";
// import { MatSidenav } from "@angular/material/sidenav";
// import { appConstants } from "src/app/app.constants";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input("sidenav") sidenav;
  apps = appConstants.apps;
  appConstants = appConstants;
  @ViewChild("bftDrop") bftDrop;
  @ViewChild("accDrop") accDrop;

  constructor(
    public dialog: MatDialog,
    public auth: AuthService,
    public router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit() {}

  toggleSidebar() {
    this.sidenav.toggle();
  }

  navigateToHome() {
    this.router.navigateByUrl("/");
  }

  openDialog(): void {
    const dialogRef = this.dialog
      .open(LoginComponent, {
        width: "500px",
        // height: "31em",
      })
      .afterClosed()
      .subscribe((res) => {
        if (res === "true") {
          this.commonService.emitLogin(true);
        } else {
        }
      });
  }

  logout() {
    this.auth.deleteToken();
    this.auth.logout();
    this.commonService.emitLogin(false);
    setTimeout(() => {
      this.router.navigate([""]);
    }, 100);
  }

  showOverlay() {
    if (this.bftDrop) {
      if (this.bftDrop.nativeElement.classList.contains("show")) {
        return true;
      }
    }
    if (this.accDrop) {
      if (this.accDrop.nativeElement.classList.contains("show")) {
        return true;
      }
    }
    return false;
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  getName() {
    if (localStorage.getItem("login_details")) {
      return JSON.parse(localStorage.getItem("login_details")).full_name;
    }
    return "";
  }

  applyNow() {
    window.open(
      "https://application.axisbank.co.in/webforms/onlineform/healthcard/web/healthcard.aspx",
      "_blank"
    );
  }
}
