export const appConstants = {
    cardIconsMapping: {
        VISA: 'visa.png',
        AMEX: 'amex-512.png',
        DINR: 'diners-club.png',
        MAST: 'master.png',
        RUPAY: 'RuPay.png',
        MASTERCARD: 'master.png',
        Unknown: 'credit.png',
    },
    apps: [
        { id: 1, name: 'Online Doctor Consultations', desc: 'Get 4 complimentary consultations per month for your family (48 in a year).', img: 'assets/icons/doc-con.png', url: '/doctor-consultation', color: '#03989E' },
        { id: 2, name: 'Health Tests & Checkups', desc: 'Get up to 50% discount on packages, plus, a complimentary Rs.500 voucher.', img: 'assets/icons/health-ch.png', url: '/health-checkup' , color: '#5D9AD8'},
        { id: 3, name: 'Personal Training & Fitness', desc: 'Get 4 free Personal Training sessions per month (48 in a year), and 16 pre-recorded videos per month.', img: 'assets/icons/fitness.png', url: '/fitness', color: '#FF9D61' },
        { id: 4, name: 'Nutrition & Diet Plans', desc: 'Get up to 50% discount from top nutrition subscriptions.', img: 'assets/icons/nutrition.png', url: '/nutrition', color: '#63459B' }
    ],

    basicInfo: {
        email: { display: 'axisbankaura@poshvine.com', val: 'axisbankaura@poshvine.com' },
        phone: { display: '1800-572-7960', val: '18005727960' }
    }
}
