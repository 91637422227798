import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { OfferService } from "src/app/offers/services/offer/offer.service";
import { ToastrService } from "ngx-toastr";
import { LoginComponent } from "src/app/shared/components/login/login.component";
import { CommonService } from "src/app/shared/services/common/common.service";
import { WelcomeBftService } from "src/app/welcome-benefit/services/welcome-bft.service";
import { appConstants } from "src/app/shared/constants/app.constants";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
})
export class HomepageComponent implements OnInit {
  merchantList = [];
  offerProgress: boolean = true;
  showCouponPop;
  selectedOffer;
  selectedmerchant;
  voucherData;
  couponLoading;
  wlcOfferDetail;
  apps = appConstants.apps;
  showAmazonVoucher = false;
  showDecathlonVoucher = false;
  loggedOut = false;

  @ViewChild("benefitContainer") benefitContainer;
  @ViewChild("carousel") private carousel: ElementRef;

  carouselOption2 = {
    center: true,
    items: 2,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 8500,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,

    responsive: {
      0: {
        items: 1,
      },
    },
  };

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public auth: AuthService,
    private offerService: OfferService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private welcomeBftService: WelcomeBftService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.commonService.login$.subscribe((res) => {
      this.offerProgress = true;
      if (res === true) {
        this.loggedOut = false;
      } else {
        this.loggedOut = true;
      }
      this.getMerchants();
      this.getSpecialBundles();
    });
  }

  getMerchants() {
    const params = {
      benefit_type: "offers",
      per_page: 6,
      page: 1,
      categories: "health-wellness",
    };
    this.offerService.getMerchants(params).subscribe((res: any) => {
      this.merchantList = res.data;
      this.offerProgress = false;
    });
  }

  navigateTo(url) {
    this.router.navigateByUrl(url);
  }

  claimOffer(event) {
    const merchantId = event.merchantId;
    const offer_id = event.offerIndex;
    let merchant;
    for (const each of this.merchantList) {
      if (each.id === merchantId) {
        merchant = each;
        break;
      }
    }
    merchant.attributes.offers.data[offer_id].showLoader = true;
    this.auth.getUserDetails().subscribe(
      (result: any) => {
        if (result && result.id) {
          this.redeem(merchant, offer_id);
        } else {
          this.dialog
            .open(LoginComponent, {
              width: "500px",
            })
            .afterClosed()
            .subscribe((res) => {
              merchant.attributes.offers.data[offer_id].showLoader = false;
              if (res === "true") {
                this.redeem(merchant, offer_id);
              } else {
              }
            });
        }
      },
      (error) => {
        this.dialog
          .open(LoginComponent, {
            width: "500px",
          })
          .afterClosed()
          .subscribe((res) => {
            merchant.attributes.offers.data[offer_id].showLoader = false;
            if (res === "true") {
              this.redeem(merchant, offer_id);
            } else {
            }
          });
      }
    );
  }

  redeem(merchant, offer_id) {
    this.showCouponPop = true;
    this.selectedOffer = {
      merchant_id: merchant.id,
      merchant_name: merchant.attributes.name,
      merchant_logo: merchant.attributes.logo_url,
      merchant_website: merchant.attributes.website,
      offer_title: merchant.attributes.offers.data[offer_id].attributes.title,
      offer_type:
        merchant.attributes.offers.data[offer_id].attributes.offer_type,
      end_date: merchant.attributes.offers.data[offer_id].attributes.end_date,
      id: merchant.attributes.offers.data[offer_id].id,
      redemption_url:
        merchant.attributes.offers.data[offer_id].attributes.redemption_url,
    };
    this.selectedmerchant = merchant;
    this.voucherData = undefined;
    this.couponLoading = true;
    let params = {};
    this.offerService.redeem(this.selectedOffer, params).then(
      (res: any) => {
        this.voucherData = res;
        this.couponLoading = false;
        this.getMerchants();
      },
      (err) => {
        this.couponLoading = false;
        this.showCouponPop = false;
      }
    );
  }

  copy(couponCode) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = couponCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toastr.success("Coupon Copied!");
  }

  goToBenefits() {
    this.benefitContainer.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  getSpecialBundles() {
    this.auth.getUserDetails().subscribe((result: any) => {
      if (result && result.id) {
        this.welcomeBftService.getSpecialBundles().then((res: any) => {
          if (res && res.data && res.data.length) {
            this.wlcOfferDetail =
              res.data[0].attributes.bundle_items.data[0].attributes.item;
            if (res.data && Array.isArray(res.data) && res.data.length > 0) {
              res.data.map((bundle) => {
                if (bundle.attributes.code === "welcome-benefitamazon") {
                  this.showAmazonVoucher = true;
                } else if (
                  bundle.attributes.code === "axis-health-welcome-benift"
                ) {
                  this.showDecathlonVoucher = true;
                }
              });
            }
          }
        });
      }
    });
  }

  goTo() {
    let gotourl = this.selectedOffer.redemption_url
      ? this.selectedOffer.redemption_url
      : this.selectedmerchant.merchant_website;
    window.open(gotourl, "_blank");
  }

  previous() {
    this.carousel.nativeElement.scrollTo({
      left:
        this.carousel.nativeElement.scrollLeft -
        this.carousel.nativeElement.offsetWidth,
      behavior: "smooth",
    });
  }

  next() {
    this.carousel.nativeElement.scrollTo({
      left:
        this.carousel.nativeElement.scrollLeft +
        this.carousel.nativeElement.offsetWidth,
      behavior: "smooth",
    });
  }

  applyNow() {
    window.open(
      "https://application.axisbank.co.in/webforms/onlineform/healthcard/web/healthcard.aspx",
      "_blank"
    );
  }
}
