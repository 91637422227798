import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "src/app/shared/services/api/api.service";

@Injectable({
  providedIn: "root",
})
export class WelcomeBftService {
  apiUrl = environment.apiUrlPsV1;

  constructor(private api: ApiService) {}

  getSpecialBundles() {
    return this.api.get(this.apiUrl + "special_bundles").toPromise();
  }

  redeemSpecialBundleOffer(params) {
    return this.api
      .post(this.apiUrl + "special_bundles/redeem_offer", params)
      .toPromise();
  }

  redeemSpecialBundle(params) {
    return this.api
      .post(this.apiUrl + "special_bundles/redeem", params)
      .toPromise();
  }

  getGiftcardsInCart(params) {
    return this.api.get(this.apiUrl + "cart", { params: params }).toPromise();
  }

  pushToCart(cartParams) {
    return this.api
      .post(this.apiUrl + "cart/add_items", cartParams)
      .toPromise();
  }

  getOrderData(id) {
    return this.api.get(this.apiUrl + `gift_card_bookings/${id}`).toPromise();
  }

  getGiftRedemptions(params = {}) {
    return this.api
      .get(this.apiUrl + "gift_card_bookings", { params: params })
      .toPromise();
  }

  claimSpecialBundle(bundle_id, item_id) {
    return this.api
      .post(`${this.apiUrl}special_bundles/${bundle_id}/redeem_gift_card`, {
        item_id: item_id,
      })
      .toPromise();
  }
}
