<div class="header-container">
  <div class="header-1">
    <div class="header-c">
      <div class="header-div">
        <div class="content-c d-flex justify-content-between">
          <div class="d-flex logo-c">
            <div class="d-block d-md-none pad-left-15" (click)="toggleSidebar()">
              <div class="icon-ham2 pointer"></div>
            </div>
            <div class="logo pointer" (click)="navigateToHome()">
              <img src="assets/axis-logo-white.png" alt="" class="img-fluid">
            </div>
          </div>
          <div class="white-bar d-flex justify-content-between">
            <div class="d-none d-md-block">
              <div class="d-flex align-center beft h-100">
                <div class="mar-right-35 relative" dropdown>
                  <div class="d-flex pointer" dropdownToggle>Benefits
                    <div class="icon-down-open-mini"></div>
                  </div>
                  <div *dropdownMenu class="dropdown-menu bft-dropdown" #bftDrop>
                    <!-- <div class="drop-top-arrow">
                <div class="arrow"></div>
              </div>
              <img src="assets/header-arrow.png" alt=""> -->
                    <div class="each-c d-flex" (click)="navigateTo(each.url)" *ngFor="let each of apps">
                      <img [src]="each.img" alt="" class="c-img img-fluid">
                      <div>
                        <div class="title" [ngStyle]="{'color': each.color}">{{each.name}}</div>
                        <div class="desc">{{each.desc}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pointer" (click)="navigateTo('/offers')">Offers</div>
              </div>
            </div>
            <div class="d-flex align-center">
            </div>
            <div class="d-flex align-center beft">
              <div class="d-flex align-center pointer relative" dropdown *ngIf="auth.isAuthenticated()">
                <div class="d-flex helpine" dropdownToggle>Helpline
                  <div class="icon-down-open-mini"></div>
                </div>
                <div *dropdownMenu class="dropdown-menu contact-us-dropdown">
                  <div>
                    <div class="desc">For any questions related to the Axis Bank AURA Credit Card, kindly
                      reach out to
                      the Helpline
                      at:</div>
                    <div class="d-flex align-center mar-top-10">
                      <div class="icon-call contact-icon"></div>
                      <a [href]="'tel:+'+ appConstants.basicInfo.phone.val"
                        class="contact-value">{{appConstants.basicInfo.phone.display}}</a>
                    </div>
                    <div class="d-flex align-center mar-top-10">
                      <div class="icon-mail contact-icon"></div>
                      <a [href]="'mailto:'+ appConstants.basicInfo.email.val"
                        class="contact-value">{{appConstants.basicInfo.email.display}}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative" dropdown  *ngIf="auth.isAuthenticated()">
                <div class="d-flex user-icon pointer" dropdownToggle>
                  <div class="icon-user1"></div>
                  <div class="icon-down-open-mini"></div>
                </div>
                <div *dropdownMenu class="dropdown-menu acc-drop" #accDrop>
                  <div class="field mar-bot-10">{{getName()}}</div>
                  <!-- <div class="field2 mar-bot-20">Cardholder since Dec 2020</div> -->
                  <hr>
                  <div class="field mar-bot-20 mar-top-20 pointer primary-hover" (click)="navigateTo('/booking')">
                    Booking
                    History</div>
                  <div class="field mar-bot-20 pointer primary-hover" (click)="navigateTo('/offer-redemption')">Offers
                    Redemption History
                  </div>
                  <div class="field mar-bot-20 pointer primary-hover"
                    (click)="navigateTo('/welcome-benefit-redemption')">
                    Welcome Benefit Claim History</div>
                  <hr>
                  <div class="field logout mar-top-15 pointer" (click)="logout()">Log out</div>
                </div>
              </div>
              <div class="apply-now pointer" *ngIf="!auth.isAuthenticated()" (click)="applyNow()">Apply Now</div>
              <button mat-button class="primary-btn login-btn" (click)="openDialog()"  *ngIf="!auth.isAuthenticated()">LOGIN</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="showOverlay()"></div>